.App {
  background-color: #f3f0ec;
  min-height: 100vh;
  font-family: Raleway;
}

h1 {
  flex-wrap: wrap;
  display: flex;
  width: 90%;
  justify-content: center;
  margin: 20px auto;
  text-align: center;
}

h2 {
  text-align: center;
}

ul {
  display: flex;
  margin: auto;
  padding: 0;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;
}

li {
 margin: 5px 15px;
  text-align: center;
}

.images {
  display: flex;
}

h3 {
  max-width: 70%;
  margin: 20px auto;
  font-size: 1.4rem;
}

p {
  max-width: 70%;
  margin: 20px auto;
  font-size: 20px;
}

.link-out {
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  box-shadow: inset 0 0 0 0 gray;

  &:hover {
    box-shadow: inset 300px 0 0 0 gray;
    color: white;
  }
}

.bottom-links {
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;

  .left-arrow {
    display: flex;

    a {
      display: flex;
      cursor: pointer;
      padding-left: .5em;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        box-shadow: inset 0 0 0 0 gray;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
        padding: 3px 5px;

        &:hover {
          box-shadow: inset 300px 0 0 0 gray;
          color: white;
        }
      }

      div {
        height: 100%;
        display: flex;
        align-items: center;

        img {
          transform: rotate(270deg);
          height: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .right-arrow {
    display: flex;

    a {
      display: flex;
      cursor: pointer;
      margin: 0;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-align: right;
        box-shadow: inset 0 0 0 0 gray;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
        padding: 2px 8px;

        &:hover {
          box-shadow: inset -300px 0 0 0 gray;
          color: white;
        }
      }

      div {
        height: 100%;
        display: flex;
        align-items: center;

        img {
          transform: rotate(90deg);
          height: 30px;
          cursor: pointer;
        }
      }
    }
  }

  @media (min-width: 2000px) {
    width: 2800px;
    margin: auto;
  }

  @media (max-width: 480px) {
    .left-arrow {
      a {
        padding-left: 0;
        font-size: 1.2rem;
      }
    }
    .right-arrow {
      a {
        font-size: 1.2rem;
      }
    }
  }
}

a {
  font-size: 1.4rem;
  font-weight: 450;
  text-decoration: none;
  color: black;
  margin: 0 -.25rem;
  padding: 0 .25rem;
}

@media (min-width: 2000px) {
  .images{
    width: 2000px;
    margin: 20px auto;
  }
}

@media (min-width: 700px) and (max-width: 1000px) {
  .images{
    .image-container{
      img {
        max-width: 40%;
      }
    }
  }
}
@media (max-width: 700px) {
  .images {
    display: flex;
    flex-direction: column;
    .image-container {
      width: 100%;
      img {
        margin-bottom: 20px;
        max-width: 40%;
      }
    }
  }

  p {
    text-align: center;
  }

  h3{
    text-align: center;
  }
}