.carousel-container {
  display: flex;
  width: 50%;
  margin: 0 auto;
  flex-direction: column;

  @media (min-width: 700px) and (max-width: 1000px) {
    width: 70%;
  }

  @media (max-width: 700px) {
    width: 90%;
  }
}

.arrow-container{
  display: flex;
}

.carousel {
  display: flex;
  overflow: hidden;
  width: 50%;
  margin: auto;
}

img {
  width: 100%;
  height: auto;
}

button {
  font-size: 18px;
  cursor: pointer;
  width: 10%;
  background-color: #f3f0ec;
  border: none;
  @media (max-width: 700px) {
    width: 20%;
  }
}

.dot-container {
  display: flex;
  justify-content: center;
  margin: 2em auto 0;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 5px;
  cursor: pointer;
}

.active {
  background-color: #555;
}