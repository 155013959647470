.resume-page {
  text-align: center;

  .resume-iframe {
    margin-top: 3.75vw;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      width: 60vw;
      height: 70vw;
      margin-bottom: 75px;
    }
  }

  .resume-mobile{
    display: none;
  }

  @media (max-width: 600px) {
    .resume-iframe{
      display: none;
    }
    .resume-mobile{
      display: flex;
      width: 100%;
      height: 60vh;
      margin: auto;
      iframe{
        margin: 0 auto;
        display: flex;
        width: 90%;
      }
    }
  }
}