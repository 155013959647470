.projects-page {
  background: linear-gradient(160deg, #101917, #f3f0ec);
  background-size: 400% 100%;
  animation: AnimationName 10s ease infinite;
  min-height: 100vh;

  .projects-page-projects {
    display: flex;
    flex-flow: row wrap;
  }

  @keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }

  h1 {
    text-align: center;
    color: white;
  }

  .intro {
    width: 80%;
    text-align: center;
    margin: auto;
    padding: 20px 0;
    color: white;
    font-size: 1.4rem;
    animation: 1s ease-out 0s 1 intro-animation;
  }

  @keyframes intro-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .project {
    display: flex;
    flex-direction: column;
    background-color: gray;
    width: 390px;
    height: 360px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 0;
    margin: auto auto 50px;
    transition: transform 250ms;
    animation: moveInBottom 1s ease-out;
    animation-fill-mode: backwards;
    border-radius: 10px;
    border: 2px solid black;

    a {
      margin: 0;
      border-radius: 10px 10px 0 0;

      &:hover {
        box-shadow: none;
      }
    }

    .projects-page-buttons {
      text-decoration: none;
      text-align: center;
      background-color: black;
      z-index: 1;
      color: white;
      border-radius: 20px;
      width: 60%;
      height: 5%;
      margin: auto;
      padding: .4em;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      font-weight: 600;
      font-size: 1rem;
    }

    &:hover {
      transform: translateY(-20px);
    }
  }

  .audio-stories-home {
    background: url("../../Assets/AudioStoriesAbout.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .audio-stories-admin-home {
    background: url("../../Assets/AudioStoriesAdminDisplayPrompts.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .application-site-home {
    background: url("../../Assets/ApplicationHome.png") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .cap-touch-screen {
    background: url("../../Assets/cap-building.jpg") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .henry-site-home {
    background: url("../../Assets/henry-gets-moving-home.jpg") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .wiki-women-home {
    background: url("../../Assets/WikiWomenHome.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .classroom-assurance-home {
    background: url("../../Assets/ClassroomLandingPage.png") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .tlz-website-home {
    background: url("../../Assets/TLZ Logo.svg") center no-repeat;
    transform: translateX(22px);
    background-size: cover;
    overflow: hidden;
    width: 90%;
    height: 80%;
  }

  .tlz-admin-home {
    background: url("../../Assets/BillyPenelope.svg") center no-repeat;
    transform: translateX(29px);
    background-size: cover;
    overflow: hidden;
    width: 80%;
    height: 80%;
  }

  .mmm-site-home {
    background: url("../../Assets/mmm-logo.jpg") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .zoom-redirect-home {
    background: url("../../Assets/ZoomRedirectHome.png") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .color-by-number-home {
    background: url("../../Assets/MUTTColorByNumberHome.png") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  .strides-home {
    background: url("../../Assets/StridesHome.png") center no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 80%;
  }

  @keyframes moveInBottom {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @media (min-width: 2000px) {
    .projects-page-projects {
      width: 1300px;
      margin: 0 auto;
    }
  }

  @media (min-width: 700px) and (max-width: 1100px) {
    background-size: 1200% 100%;
    .projects-page-projects {
      .project {
        width: 360px;

        .projects-page-buttons {
          margin: auto;
        }
      }

      .tlz-website-home {
        transform: translateX(3px);
        width: 100%;
      }
    }
  }

  @media (max-width: 700px) {
    background-size: 2000% 100%;
    .projects-page-projects {
      display: flex;
      flex-direction: column;
    }

    .project {
      width: 300px;
      height: 300px;

      .projects-page-buttons {
        width: 70%;
        padding-bottom: .8em;
        margin: auto;
      }
    }

    .project img {
      max-width: 90%;
      max-height: 90%;
    }


    .tlz-website-home {
      background: url("../../Assets/TLZ Logo.svg") center no-repeat;
      transform: translateX(2px);
      background-size: cover;
      overflow: hidden;
      width: 100%;
      height: 80%;
    }
  }
}