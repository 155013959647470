.cap-touch-screen {
  .images {
    flex-direction: column;
  }

  .after {
    .carousel{
      width: 70%;
    }
  }
}