.contact-me {
  .positioning {
    display: flex;
    height: 50vh;
    align-items: center;
  }

  .footer-icons {
    background-color: grey;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    margin: auto;
    align-content: center;
    border-radius: 20px;
    padding: 25px 0;
  }

  .links {
    display: flex;
    justify-content: center;

    a {
      width: 30%;
    }
  }

  p {
    font-size: 42px;
    max-width: 100%;
  }

  a {
    color: black;
    font-size: 2rem;
    font-weight: 500;
    box-shadow: none;
    transition: none;
    margin: 0;
    padding: 0;
    text-decoration: underline;

    &:hover {
      color: lightgrey;
      box-shadow: none;
    }
  }

  img {
    display: flex;
    justify-content: center;
    margin: 10px 40px;
    max-width: 30%;
  }

  a {
    display: flex;
    justify-content: center;
  }


  @media (min-width: 600px) and (max-width: 850px) {
    .footer-icons {
      width: 80%;
    }
    img {
      max-width: 40%;
    }
    a {
      font-size: 1.8rem;
    }
    p {
      font-size: 30px;
    }
  }

  @media (max-width: 600px) {
    .footer-icons {
      width: 80%;
      padding: 25px;
    }
    img {
      max-width: 40%;
    }
    a {
      font-size: 1.8rem;
    }
    p {
      font-size: 24px;
    }
    .links a {
      width: 50%;
    }
  }
}
