.wiki-women {
  h1{
    text-align: center;
  }
  h2{
    text-align: center;
  }
  ul {
    display: flex;
    margin: auto;
    padding: 0;
    justify-content: center;
    list-style: none;
  }

  li {
    margin-left: 15px;
    margin-right: 15px;
  }

  .images{
    display: flex;
    justify-content: space-evenly;
    img{
      max-width: 75%;
      max-height: 60vh;
      margin: auto;
      border: 1px solid black;
    }
  }
  p{
    max-width: 70%;
    margin: 20px auto;
    font-size: 20px;
  }

  @media (min-width: 700px) and (max-width: 1200px){
    .images{
      img{
        max-width: 45%;
        max-height: 40%;
      }
    }
  }
  @media (max-width: 700px) {
    .images {
      display: flex;
      flex-direction: column;
      img {
        margin-bottom: 20px;
      }
    }
    p {
      text-align: center;
    }
  }
}