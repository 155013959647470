.menu-nav {
  .navigation {
    width: 100%;
    height: 50px;

    ul {
      z-index: 10;
      height: 100%;
      width: 20vw;
      margin-top: 50px;
      margin-bottom: 0;
      position: absolute;
      right: 0;
      background-color: black;
    }

    .open {
      display: inline;
    }

    .closed {
      display: none;
    }

    li {
      list-style-type: none;
      padding-right: 10px;
      margin: 10px 0;
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .hamburger {
    position: absolute;
    padding-top: 25px;
    margin-left: 10px;
    z-index: 10;
    top: 0;
  }

  @media (min-width: 1050px) {
    .hamburger {
      display: none;
    }
  }

  @media (max-width: 750px) {
    .navigation {
      ul {
        width: 50vw;
      }
    }
  }
}