.header {
  display: flex;
  background-color: black;
  height: 75px;
  align-items: center;
  justify-content: space-between;

  .header-links {
    display: flex;
    height: 50%;
    align-items: center;
    width: 40%;
    margin-right: 10px;
    font-weight: 500;

    a {
      font-size: 20px;
      font-weight: 600;
      box-shadow: none;
      margin: auto;
      padding: 0;
      transition: none;

      &:hover {
        box-shadow: none;
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
    width: 25%;
    text-align: center;
  }

  a:hover, a:active {
    color: gray;
  }

  .header-name {
    display: flex;
    height: 60%;
    align-items: center;
    width: 50%;

    a {
      margin-left: 20px;
      width: 100%;
      font-size: 2.2rem;
      font-weight: 600;
      box-shadow: none;
      padding: 0;
      transition: none;
      text-align: left;

      &:hover {
        box-shadow: none;
      }
    }
  }

  .hamburger-menu {
    display: none;
  }

  @media (min-width: 600px) and (max-width: 850px) {
    .hamburger-menu {
      display: flex;
      width: 50px;
      margin-right: 20px;
    }

    .header-name {
      a {
        font-size: 2rem;
      }
    }
    .header-links {
      display: none;
    }
  }
  @media (max-width: 600px) {
    height: 75px;
    .hamburger-menu {
      display: flex;
      width: 50px;
      margin-right: 10px;
    }
    a {
      width: 75%;
      text-align: center;
      margin-bottom: 3px;
    }
    .header-name {
      a {
        font-size: 1.8rem;
      }
    }
    .header-links {
      display: none;
    }
  }
}